import { render, staticRenderFns } from "./popEdit.vue?vue&type=template&id=63b02782&scoped=true&"
import script from "./popEdit.vue?vue&type=script&lang=js&"
export * from "./popEdit.vue?vue&type=script&lang=js&"
import style0 from "./popEdit.vue?vue&type=style&index=0&id=63b02782&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "63b02782",
  null
  
)

export default component.exports