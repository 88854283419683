<template>
    <div class="dashboard-search-card-container ">
      <le-card width="100%" height="150px" >
        <template>
          <div class="container">
            <div class="card-title">搜索</div>
            <div class="search-box">
              <div class="time-box">
              <div class="time-title">起止时间：</div>
              <a-month-picker v-model="month" placeholder="选择月份" class="input-month"></a-month-picker>
              <a-input v-model="search" placeholder="请输入主播昵称或uid" class="input-search" :maxLength="20" :allowClear="true" @change="changeSearch()"  />
              <div class="button-box">
                <le-button  @btnClick="searchClick" />
              </div>
              </div>
            </div>
          </div>
        </template>
      </le-card>
    </div>
</template>

<script>
import moment from 'moment'
import tool from '@/utils/tool'
export default {
  components: { 
  },
  data() {
    return {
      month:null,
      search:''
    };
  },
  computed: {
  },
  mounted(){

  },
  methods: {
    searchClick(){
      console.log(  moment(this.month).format('YYYY-MM'),this.search)
      if(!this.month&&!this.search){
        this.$message.error('请填入搜索条件');
        return false
      }
      let param={}

      if(this.month){
        param.month=moment(this.month).format('YYYY-MM')
      }
      if(this.search){
        param.search=this.search
      }

      tool.$emit('search', param)
    },
    changeSearch(){
      console.log('123')
      let param={}
      if(!this.search){
        tool.$emit('clear', param)
      }
      
    }
  }
};
</script>

<style lang="less" scoped>
.dashboard-search-card-container {
.container{
  position: relative;
  .title{
    padding-top: 20px;
    margin-left: 30px;
    font-size: 30px;
    font-weight: bold;
    color: #333333;
  }
  .search-box{
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    .time-box{
      display: flex;
      align-items: center;
      .time-title{
        margin-left: 30px;
        font-size: 18px;
        color: #333333;
      }
      .input-month{
        width: 200px;
      }
      .input-search{
        width:200px;
        margin-left:10px
      }
      .button-box{
        margin-left: 30px;
      }
    }

  }
}
}

@media screen and(max-width:750px) {
  .dashboard-search-card-container{
    .container{
  position: relative;
  .search-box{
    margin-top: 10px;
    .time-box{
      flex-wrap: wrap;
      .time-title{
        margin-left: 20px;
        font-size: 16px;
      }
      .input-month{
        width: 150px;
      }
      .input-search{
        width:150px;
        margin-left:20px;
      }
          .button-box{
      margin-left: 20px;
      margin-top: 5px;
    }
    }
  }
  }
  }
}




</style>
