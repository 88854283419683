<template>
    <div class="dashboard-table-card-container ">
      <le-card width="100%" style="padding-bottom:20px" >
        <template>
          <div class="container">
            <div class="card-title" style="display: flex;" >超级管理员 彗星号的团队 
              <div class="num-box">{{pageOptions.total}}人</div>
            </div>
            <le-button txt="拉取" class="le-button" @btnClick="rePull()" />
            <div class="table-box">
                <a-table 
                :columns="columns" 
                :data-source="dataSource"
                :loading="loading"
                :pagination="pageOptions"
                @change="handleTableChange"
                size="middle" >

                <div slot="uname"  slot-scope="row">
                  <a class="name-box" :href="'https://space.bilibili.com/'+row.uid" target="_blank" rel="noopener noreferrer">
                    <div class="avatar"  >
                      <img :src="row.face_url" alt="" srcset="">
                      </div>
                    <div class="name-uid">
                      <div>{{row.uname}}</div>
                      <div>{{row.uid}}</div>
                    </div>
                  </a>
                </div>

                <span slot="customTitle">开播率 
                   <a-tooltip>
                  <template slot="title">
                    近3天直播过 记为有效
                  </template>
                    <a-icon type="question-circle" />
                    </a-tooltip>
                  </span>
                  <div slot="identity" slot-scope="row" >
                      <div>{{row==1?'经纪人':'工会' }}</div>
                  </div>

                  <div slot="login" slot-scope="row" class="login" @click="showLog(row.uid)">
                      <div>{{row.last_login_time | kong}}</div>
                      <div>{{row.login_info}}</div>
                  </div>
                  <span slot="action" style="display: flex;justify-content:space-between" slot-scope="row">
                    <le-button txt="编辑" width="40px" height="30px" fontSize="14px" @btnClick="clickEdit(row)" />
                    <le-button txt="删除" width="40px" height="30px" fontSize="14px" @btnClick="clickGoto(row)" />
                  </span>
              </a-table>
            </div>
          </div>
        </template>
      </le-card>
      <popEdit v-if="visible" :visible="visible"  @handleOk="handleOk"  @handleCancel="handleCancel" :row="editRow"  />
      <popLoginInfo v-if="visibleLog" :visible="visibleLog"  @handleOk="handleOk"  @handleCancel="visibleLog=false" :uid="uid"  />
    </div>
</template>

<script>

const columns = [
  {
    title: '任务群体',
    key: 'identity',
    dataIndex: 'identity',
    scopedSlots: { customRender: "identity" },
  },
  {
    title: '任务类型',
    scopedSlots: { customRender: "uname" },
  },
  {
    title: '任务昵称',
    dataIndex: 'new_anchor',
    sorter: (a, b) => a.new_anchor - b.new_anchor,
  },
  {
    title: '任务目标',
    dataIndex: 'income',
    sorter: (a, b) => a.income - b.income,
  },

  {
    title: '任务时间',
    dataIndex: 'total_anchor',
    sorter: (a, b) => a.total_anchor - b.total_anchor,
  },

  {
    title: '奖励',
    dataIndex: 'validlive',
    sorter: (a, b) => a.validlive - b.validlive,
  },
  {
      title: "操作",
      key: "action",
      width: 110,
      scopedSlots: { customRender: "action" },
  },
];

import popEdit from './popEdit'
import popLoginInfo from './popLoginInfo'
import tool from '@/utils/tool'
import {MixinList} from '@/common/mixin';
export default {
  components: {
    popEdit,
    popLoginInfo
  },
  data() {
    return {
      columns,
      dataUrl:'v1/user/list',
      delUrl:'admin/layout/del',
      visible: false,
      visibleLog: false,
      uid:'',
      editRow:{},

    };
  },
  computed: {
  },
  mixins:[MixinList],
  mounted(){
    // if(this.$route.query.temp_uid){
    //   this.dataUrl='v1/user/list?temp_uid='
    // }
    // console.log(this.$route.query.temp_uid)

  },
  methods: {
    clickEdit(row){
      this.editRow=row
      this.visible = true;
    },
    handleOk(){
      this.visible = false;
      this.getData()
    },
    handleCancel(){
      this.visible = false;
      this.getData()
    },
    clickGoto(row){
       let routeData = this.$router.resolve({ path: '/index/dashboard', query: {  temp_uid: row.uid } });
        window.open(routeData.href, '_blank'); 
      // window.open('https://www.bilibililink.com/index/dashboard?temp_uid='+row.uid)
      // window.open('http://localhost:8080/index/dashboard?temp_uid='+row.uid)
    },
    showLog(uid){
      console.log('uid',uid)
      this.uid=uid
      this.visibleLog=true
    },
    rePull(){
        this.$api.get(`v1/user/sync`).then((res) => {
          if(res.code==200){
          this.dataSource=res.data.list.map((d) => {
            d.key = d.created_at
            return d
          })
          }else{
          this.$message.error(res.message)
          }
        });
    }
  }
};
</script>

<style lang="less" scoped>
.dashboard-table-card-container {
.container{
  position: relative;
     .num-box{
      font-size: 14px;
      margin-left: 11px;
      line-height: 32px;
    }
  .le-button{
    position: absolute;
    top: 30px;
    right: 30px;
  }

  .table-box{
    margin: 40px 30px;
  }
}
}

.login{
  cursor: pointer;
  &:hover{
    color: #008DF0;
  }
}

.name-box{
  display: flex;
  .avatar{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
    background-color: #333333;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    img{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    }
  }
}

@media screen and(max-width:750px) {
 .dashboard-table-card-container {
.container{
  .title{
    padding-top: 10px;
    margin-left: 20px;
    font-size: 20px;
    .num-box{
      font-size: 12px;
      margin-left: 5px;
      line-height: 22px;
    }
  }
  .le-button{
    position: absolute;
    top: 10px;
    right: 20px;
    width: 50px!important;
  }

  .table-box{
    margin: 20px;
    overflow-x:scroll ;
  }
}
}
}

</style>
