import { render, staticRenderFns } from "./searchCard.vue?vue&type=template&id=bb87cba4&scoped=true&"
import script from "./searchCard.vue?vue&type=script&lang=js&"
export * from "./searchCard.vue?vue&type=script&lang=js&"
import style0 from "./searchCard.vue?vue&type=style&index=0&id=bb87cba4&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bb87cba4",
  null
  
)

export default component.exports